import * as Dialog from '@radix-ui/react-dialog'
import styles from './StausModal.module.css'
import { Dispatch, SetStateAction, useState } from 'react'
import { PaymentTicket, TicketListObject } from '../core/_models'
import { internalFinishTicketAPI } from '../core/_requests'
import clsx from 'clsx'

const StatusModal = ({
  isOpen,
  setIsOpen,
  targetTicket,
  setTargetTicket,
  setTickets,
}: {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  targetTicket: PaymentTicket | null
  setTargetTicket: Dispatch<SetStateAction<PaymentTicket | null>>
  setTickets: Dispatch<SetStateAction<TicketListObject>>
}) => {
  const [input, setInput] = useState<number>(0)

  const sumbitHandler = async () => {
    if (targetTicket?.externalUser?.id) {
      const response = await internalFinishTicketAPI(
        targetTicket.token,
        String(targetTicket.externalUser.id),
        String(input)
      )
      if (response?.status === 200) {
        setTickets((prev: any) => {
          return { ...prev, [targetTicket.token]: response?.data.ticket }
        })
        setTargetTicket(null)
        setIsOpen(false)
      } else {
        setTickets((prev: any) => ({
          ...prev,
          [targetTicket?.token as string]: { ...targetTicket, status: 'EXPIRED' },
        }))
      }
    }
  }

  return (
    <div style={{ position: 'absolute' }}>
      <Dialog.Root open={isOpen}>
        <Dialog.Portal>
          <Dialog.Overlay
            className={styles.overlay}
            onClick={() => {
              setTickets((prev: any) => ({
                ...prev,
                [targetTicket?.token as string]: { ...targetTicket, status: 'EXPIRED' },
              }))
              setIsOpen(false)
            }}
          />
          <Dialog.Content className={clsx(styles.content, 'bg-secondary')}>
            <div className={styles.inpits}>
              <label htmlFor='exampleInputEmail1' className='form-label mb-6'>
                Введите сумму
              </label>
              <input
                type='number'
                min='0'
                className='form-control'
                id='exampleInputEmail1'
                aria-describedby='emailHelp'
                placeholder='Сумма'
                onChange={(e) => setInput(Number(e.target.value))}
              />
            </div>
            <div className={styles.buttons}>
              <button
                onClick={sumbitHandler}
                type='button'
                className={`btn btn-primary ${input === 0 ? 'disabled' : ''}`}
              >
                Завершить
              </button>
              <button
                onClick={() => {
                  setIsOpen(false)
                  setTickets((prev: any) => ({
                    ...prev,
                    [targetTicket?.token as string]: { ...targetTicket, status: 'EXPIRED' },
                  }))
                  setTargetTicket(null)
                }}
                type='button'
                className='btn btn-danger'
              >
                Отмена
              </button>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  )
}

export default StatusModal
