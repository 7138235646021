import { ChangeEvent, useState } from 'react'
import DateTimeRangeFilter, { DateFilterObject } from '../../../common/filter/DateTimeRangeFilter'
import { SelectData } from '../../../common/filter/models'
import CashBoxFilter, {
  selectType as cashBoxSelectType,
} from '../../../common/filter/CashBoxFilter'
import PaymentAdapterFilter, {
  selectType as paymentAdapterType,
} from '../../../common/filter/PaymentAdapterFilter'
import MerchantFilter, {
  selectType as merchantSelectType,
} from '../../../common/filter/MerchantFilter'
import { IsGranted } from '../../auth/PrivateRoute'
import { ROLE, useAuth } from '../../auth'
import { TReportType } from '../ReportsPage.types'
import PaymentMethodsFilter, {
  selectType as paymentSelectType,
} from '../../../common/filter/PaymentMethodsFilter'

const ReportTicketsFilter = ({
  initialFilter,
  updateFilters,
  callback,
  typeReport,
}: {
  initialFilter: any
  updateFilters: (key: any) => void
  callback: (key?: boolean, reset?: boolean) => void
  typeReport: TReportType
}) => {
  const { currentUser } = useAuth()
  const [filterBy, setFilterBy] = useState<string>('byCreatedDate')

  const onFilterByChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterBy(e.target.value)

    updateFilters({
      ...initialFilter,
      byCompletionDate: e.target.value === 'byCompletionDate',
    })
  }

  const updateTime = (filterData: DateFilterObject) => {
    updateFilters({
      ...initialFilter,
      dateStart: filterData.dateStart,
      dateEnd: filterData.dateEnd,
    })
  }

  const onChangeSelect = (selectData: SelectData) => {
    const filterObject = { ...initialFilter }

    switch (selectData.type) {
      case cashBoxSelectType:
        if (!selectData.value.length) {
          filterObject.cashBoxIdList = []
        } else {
          filterObject.cashBoxIdList = selectData.value
        }

        break
      case paymentAdapterType:
        if (!selectData.value.length) {
          filterObject.paymentAdapterIdList = []
        } else {
          filterObject.paymentAdapterIdList = selectData.value
        }

        break
      case merchantSelectType:
        if (!selectData.value.length) {
          filterObject.merchantTokenList = []
        } else {
          filterObject.merchantTokenList = selectData.value
        }

        break
      case paymentSelectType:
        if (!selectData.value.length) {
          filterObject.paymentMethods = []
        } else {
          filterObject.paymentMethods = selectData.value
        }

        break
    }

    updateFilters(filterObject)
  }

  return (
    <>
      <div className='row'>
        <div className='col-lg-9'>
          <div className='row mb-7'>
            <div className='col'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  id='by-created-date-radio'
                  className='form-check-input'
                  type='radio'
                  value='byCreatedDate'
                  name='report-sort-type-radio'
                  onChange={onFilterByChange}
                  checked={filterBy === 'byCreatedDate'}
                />
                <label className='form-check-label' htmlFor='by-created-date-radio'>
                  По дате создания
                </label>
              </div>
            </div>
            <div className='col'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  id='by-completion-date-radio'
                  className='form-check-input'
                  type='radio'
                  value='byCompletionDate'
                  name='report-sort-type-radio'
                  onChange={onFilterByChange}
                  checked={filterBy === 'byCompletionDate'}
                />
                <label className='form-check-label' htmlFor='by-completion-date-radio'>
                  По дате закрытия
                </label>
              </div>
            </div>
          </div>
          <DateTimeRangeFilter filter={initialFilter} updateTime={updateTime} />
        </div>
        <div className='d-flex flex-column flex-sm-row flex-lg-column col-lg-3 gap-4 mt-2'>
          <button className='btn btn-success' onClick={() => callback()}>
            Фильтровать
          </button>

          <button
            className='btn btn-primary'
            onClick={() => {
              setFilterBy('byCreatedDate')
              callback(false, true)
            }}
          >
            Сбросить фильтр
          </button>

          <button className='btn btn-primary' onClick={() => callback(true)}>
            Экспортировать
          </button>
        </div>
        {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) ? (
          <>
            <div className='col-lg-4 mt-10'>
              <CashBoxFilter
                isMultiple
                changeSelect={onChangeSelect}
                value={initialFilter.cashBoxIdList}
              />
            </div>
            <div className='col-lg-4 mt-10'>
              <MerchantFilter
                isMultiple
                changeSelect={onChangeSelect}
                value={initialFilter.merchantTokenList}
              />
            </div>
          </>
        ) : null}

        {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) ? (
          <>
            <div className='col-lg-4 mt-10'>
              <PaymentAdapterFilter
                isMultiple
                changeSelect={onChangeSelect}
                value={initialFilter.paymentAdapterIdList}
              />
            </div>
          </>
        ) : (
          ''
        )}

        {IsGranted(
          [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_SENIOR_OPERATOR],
          currentUser
        ) ? (
          <>
            <div className='col-lg-4 mt-10'>
              <PaymentMethodsFilter
                isMultiple
                changeSelect={onChangeSelect}
                value={initialFilter.paymentMethods}
              />
            </div>
            {typeReport === 'payment' && (
              <div className='col-lg-4 mt-10 d-flex align-items-center'>
                <label className='form-check-label me-3' htmlFor='flexCheckDefault'>
                  Без платежного метода
                </label>
                <input
                  className='form-check-input'
                  type='checkbox'
                  onChange={(e) => {
                    updateFilters((prev: any) => ({
                      ...prev,
                      withoutPaymentMethod: e.target.checked,
                    }))
                  }}
                  checked={initialFilter.withoutPaymentMethod}
                  id='flexCheckDefault'
                />
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default ReportTicketsFilter
